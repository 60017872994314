import { NavbarLink } from '@components/layout/Navbar/NavbarLink/NavbarLink';
import { useNavbarContext } from '@contexts/NavbarContext';
import { FunctionComponent } from 'react';
import { NavbarMenuItemProps } from '../NavbarMenu/NavbarMenu.types';
import { Li, Ul } from './NavbarDropdown.styles';
import { NavbarDropdownLinkProps } from './NavbarDropdown.types';

export const NavbarDropdown: FunctionComponent<NavbarMenuItemProps> = ({
  isLast,
  name,
  dropdown,
}) => {
  const { setIsMobileMenuOpen } = useNavbarContext();
  const onDropdownLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <Ul key={`dropdown-${name}`} isLast={isLast}>
      {dropdown.map((link: NavbarDropdownLinkProps) => (
        <NavbarLink
          key={`dropdown-${name}-${link.name}`}
          href={link.href}
          nofollow={link.nofollow}
          onClick={onDropdownLinkClick}
        >
          <Li visibleOn={link.visibleOn}>{link.name}</Li>
        </NavbarLink>
      ))}
    </Ul>
  );
};
