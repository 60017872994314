import { GlobalNavbarButtons } from '@api/api';
import { NavbarMenuItemProps } from '@components/layout/Navbar/NavbarMenu/NavbarMenu.types';
import { ImageContainer } from '@components/layout/Page/Page.styles';
import Image from 'next/image';

const doesButtonHave = (buttons: Array<GlobalNavbarButtons>, key: string): boolean =>
  buttons.some((button) => button[key]);

export const useNavbarButtons = (buttons?: Array<GlobalNavbarButtons>): NavbarMenuItemProps[] => {
  if (!buttons) {
    return [];
  }

  const dropdownItems = buttons.map((button) => ({
    name: button.name,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore:
    href: button.url as string,
    nofollow: true,
    visibleOn: {
      desktop: button.dropdownOnDesktop,
      tablet: button.dropdownOnTablet,
      mobile: button.dropdownOnMobile,
    },
  }));

  return [
    {
      name: 'Sponsored',
      hover: false,
      underline: true,
      dropdown: dropdownItems,
      visibleOn: {
        desktop: doesButtonHave(buttons, 'dropdownOnDesktop'),
        tablet: doesButtonHave(buttons, 'dropdownOnTablet'),
        mobile: doesButtonHave(buttons, 'dropdownOnMobile'),
      },
    },
    ...buttons.map((button) => ({
      name: button.name,
      href: button.url,
      underline: false,
      nofollow: true,
      hover: false,
      visibleOn: {
        desktop: !button.dropdownOnDesktop,
        tablet: !button.dropdownOnTablet,
        mobile: !button.dropdownOnMobile,
      },
      icon: (
        <ImageContainer>
          <Image
            src={(button.image as { url: string })?.url}
            alt={button.name}
            width={20}
            height={20}
            unoptimized
          />
        </ImageContainer>
      ),
    })),
  ];
};
